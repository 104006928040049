import React from 'react';

const ContactForm = () => {
  return (
    <iframe
      src='https://docs.google.com/forms/d/e/1FAIpQLSdUS5qSfhuZXQs4HLjOndSE8-ZCAgKuRwwX5rWyyYac7kKzhg/viewform?embedded=true'
      style={{ width: '900px', height: '1300px' }}
    />
  );
};

export { ContactForm };
