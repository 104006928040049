import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { ContactForm } from '../forms';

const ContactPageTemplate = ({
  title,
  logo,
  tel,
  instantmessaging,
  subtitle,
  meta_title,
  meta_description,
}) => {
  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
      </Helmet>
      <section className='hero is-primary is-bold is-medium'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-one-fifth is-1'>
                <img alt='' src={logo} />
              </div>
              <div className='column is-10'>
                <div className='section'>
                  <h1 className='title has-text-justified'>{title}</h1>
                  <h2 className='subtitle'>{subtitle}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <ContactForm />
          <p>
            <img alt='' src={tel} />
            <img alt='' src={instantmessaging} />
          </p>
        </div>

        <div className='columns is-centered'>
          <div className='column is-1 is-offset-1'>
            <a href='https://www.facebook.com/beetesla.rs'>
              <img src='/img/facebookLink.png' width='50' height='100' alt='' />
            </a>
          </div>
          <div className='column is-1'>
            <a href='https://www.instagram.com/beetesla/'>
              <img
                src='/img/instagramIcon.png'
                width='50'
                height='100'
                alt=''
              />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.string,
  tel: PropTypes.string,
  instantmessaging: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
};

export default ContactPageTemplate;
